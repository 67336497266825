import { Layout } from "antd";
import styled from "styled-components";

export const MySider = styled(Layout.Sider)`
  .ant-menu-inline,
  .ant-layout-sider-children,
  .ant-menu-inline-collapsed {
    background: #2c374b !important;
  }
`;
