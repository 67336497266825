import styled from "styled-components";
import { Layout } from "antd";
import back from "../../../../assets/images/back.jpg";

export const TitleCon = styled.div`
  padding: 10px;
  margin-bottom: 40px;
  text-align: center;
`;

export const Title = styled.span`
  color: white;
  font-family: "Monda", sans-serif;
  font-size: 40px;
  @media (max-width: 1000px) {
    font-size: 25px;
  }
`;

export const LoginMainCon = styled.div`
  background: url(${back}) no-repeat center center fixed;
  height: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`;
export const LayoutIt = styled(Layout)`
  position: fixed;
  width: 100%;
  top: 0;
  background: transparent;
`;
