import React from "react";
import logo from "../../../../assets/images/logo_light.png";

export const Logo = () => {
  return (
    <div>
      <img src={logo} alt="logo" />
    </div>
  );
};
