// react
import React from "react";
// third party
import { LogoutOutlined } from "@ant-design/icons";
import { Button, Row, Col } from "antd";
// custom
import { NavContainer, ExitButtonContainer, TriggerIcon } from "../atoms";
import Clock from "../atoms/Clock";

export const Navbar = (props) => {
  const { collapsed, toggle, history } = props;
  // back to the login page
  const handleExit = () => {
    localStorage.clear();
    sessionStorage.clear();
    history.push("/");
  };
  return (
    <NavContainer>
      <Row>
        <Col span={18}>
          {/* toggler */}
          <TriggerIcon collapsed={collapsed} toggle={toggle} />
          <Clock />
        </Col>
        <ExitButtonContainer span={6}>
          {/* Logout button */}
          <Button onClick={handleExit} icon={<LogoutOutlined />}>
            Exit
          </Button>
        </ExitButtonContainer>
      </Row>
    </NavContainer>
  );
};
