import React from "react";
import { Row, Col } from "antd";
import { Logo, NavCon } from "../atoms";

export const Navbar = () => {
  return (
    <NavCon>
      <Row>
        <Col span={12}>
          <Logo />
        </Col>
        <Col span={12}></Col>
      </Row>
    </NavCon>
  );
};
