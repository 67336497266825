import React from "react";
import { Drawer, Layout } from "antd";
// third party
import { Menu } from "antd";
import styled from "styled-components";
import { EnvironmentOutlined, SettingOutlined, HistoryOutlined, ClusterOutlined } from "@ant-design/icons";
// custom
import { Logo } from "../atoms";
import img from "../../../../assets/images/logo.svg";

const { Sider } = Layout;

const AppDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0;
  }
`;

const { SubMenu } = Menu;

const MobileSideNav = ({ user, collapsed = false, toggle, onMenuClick, currentPathname }) => {
  return (
    <AppDrawer
      closable={false}
      visible={collapsed}
      placement="left"
      className="d-md-none app-drawer"
      width={250}
      onClose={toggle}
    >
      <Sider trigger={null} collapsible id="app-sidenav" width="100%" style={{ height: "100%" }}>
        <Logo className="logo">
          <img alt="logo" style={{ width: "100%", height: "100%" }} src={img}></img>
        </Logo>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={["1"]}
          onClick={(item) => onMenuClick(item.key)}
          selectedKeys={[currentPathname]}
        >
          {user.map && (
            <Menu.Item key="/content/map" icon={<EnvironmentOutlined />}>
              Map
            </Menu.Item>
          )}
          {user.dm && (
            <Menu.Item key="/content/device" icon={<ClusterOutlined />}>
              Device Manager
            </Menu.Item>
          )}
          {user.df && (
            <SubMenu key="sub1" icon={<SettingOutlined />} title="Database fixer">
              <Menu.Item key="/content/database">Fixer</Menu.Item>
              <Menu.Item key="/content/editor">Editor</Menu.Item>
            </SubMenu>
          )}
          {user.history && (
            <Menu.Item key="/content/history" disabled icon={<HistoryOutlined />}>
              History
            </Menu.Item>
          )}
        </Menu>
      </Sider>
    </AppDrawer>
  );
};

export default MobileSideNav;
