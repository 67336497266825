class Check {
  constructor() {
    this.logged = sessionStorage.getItem("logged");
  }

  login(callBack) {
    this.logged = true;
    callBack();
  }

  logout(callBack) {
    this.logged = false;
    callBack();
  }

  isChecked() {
    return this.logged;
  }
}

export default new Check();
