// export const url = "http://192.168.2.3:1808/api";

// export const url = "http://101.4.255.243:1808/api";
// export const url = "https://api.fizmasoft.uz/mvx/api";

var isDomain = !(
  /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/.test(window.location.host) || window.location.host == "localhost"
);

var api = {
  protocol: window.location.protocol,
  host: window.location.hostname,
  path: isDomain ? "/api" : "",
  port: isDomain ? window.location.port : 1808,
};

const host = window.location.hostname;
export const url = `${api.protocol}//${api.host}:${api.port}${api.path}`;

console.log(url);

export const iframe = `./`;
export const imgUrl = `${url}/dm/devices/photo`;
