import React from "react";
import styled from "styled-components";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";

const TriggerContainer = styled.span`
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
`;

export const TriggerIcon = ({ collapsed, toggle }) => {
  return (
    <TriggerContainer onClick={toggle}>
      {collapsed ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
    </TriggerContainer>
  );
};
