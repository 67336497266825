// react
import React from "react";
// third party
import { Route, Switch } from "react-router-dom";
import loadable from "react-loadable";
import styled from "styled-components";
import { Layout } from "antd";

let { Content } = Layout;

let Map = loadable({
  loader: () => import("../../routes/map"),
  loading: () => <h2>Loading . . .</h2>,
});

let DatabaseFixer = loadable({
  loader: () => import("../../routes/df"),
  loading: () => <h2>Loading . . .</h2>,
});
let DeviceManager = loadable({
  loader: () => import("../../routes/dm"),
  loading: () => <h2>Loading . . .</h2>,
});

let Editor = loadable({
  loader: () => import("../../routes/editor"),
  loading: () => <h2>Loading . . .</h2>,
});

const Routes = ({ match, user }) => {
  return (
    <Switch>
      {user.map && <Route path={`${match.url}/map`} component={Map} />}
      {user.id === 1 && <Route path={`${match.url}/editor`} component={Editor} />}
      {user.dm && (
        <Route
          path={`${match.url}/device`}
          component={(props) => (
            <SContent>
              <DeviceManager {...props} />
            </SContent>
          )}
        />
      )}
      {user.df && <Route path={`${match.url}/database`} component={DatabaseFixer} />}
    </Switch>
  );
};

const SContent = styled(Content)`
  margin: 24px 16px;
  height: 100%;
  overflow: auto;
  background-color: white;
`;

export const IContent = (props) => {
  const { match, user } = props;
  return <Routes match={match} user={user} />;
};
