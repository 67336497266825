// react
import React from "react";
// third party
import { Menu } from "antd";
import { EnvironmentOutlined, SettingOutlined, HistoryOutlined, ClusterOutlined } from "@ant-design/icons";
// custom
import { Logo, MySider } from "../atoms";
import img from "../../../../assets/images/logo.svg";

const { SubMenu } = Menu;

export const SideBar = ({ user, collapsed, onMenuClick, currentPathname }) => {
  return (
    <MySider trigger={null} collapsible collapsed={collapsed} className="app-sidenav d-none d-md-flex">
      <Logo className="logo">
        <img alt="logo" style={{ width: "100%", height: "100%" }} src={img}></img>
      </Logo>
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={["1"]}
        onClick={(item) => onMenuClick(item.key)}
        selectedKeys={[currentPathname]}
      >
        {user.map && (
          <Menu.Item key="/content/map" icon={<EnvironmentOutlined />}>
            Map
          </Menu.Item>
        )}
        {user.dm && (
          <Menu.Item key="/content/device" icon={<ClusterOutlined />}>
            Device Manager
          </Menu.Item>
        )}
        {user.df && (
          <SubMenu key="sub1" icon={<SettingOutlined />} title="Database fixer">
            <Menu.Item key="/content/database">Fixer</Menu.Item>
            {user.id === 1 && <Menu.Item key="/content/editor">Editor</Menu.Item>}
          </SubMenu>
        )}

        {user.history && (
          <Menu.Item key="/content/history" disabled icon={<HistoryOutlined />}>
            History
          </Menu.Item>
        )}
      </Menu>
    </MySider>
  );
};
