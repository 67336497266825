import React, { useEffect } from "react";

import axios from "axios";
import { Form, notification } from "antd";
import { LoginOutlined, UserOutlined, KeyOutlined, FrownOutlined } from "@ant-design/icons";
import { isMobile } from "react-device-detect";

import Check from "../func/check";
import { url } from "../../../../constants";
import { FormCon, MyForm, TitleCon, Title, MyInput, MyInputPassword, SubmitBtn, MyRow } from "../atoms";

const openNotification = (status) => {
  notification.open({
    message: "Error",
    description: `${status} message`,
    icon: <FrownOutlined style={{ color: "red" }} />,
    duration: 2,
  });
};

export const FormIt = ({ history }) => {
  const handleSubmit = (val) => {
    console.log(url);
    axios
      .post(`${url}/auth`, {
        username: val.username,
        password: val.password,
      })
      .then((response) => {
        const { status, token } = response.data;
        if (status === 200) {
          localStorage.setItem("token", token);
          Check.login(() => {
            history.push("/content/map");
          });
        } else if (status === 401) {
          openNotification(status);
        } else if (status === 404) {
          openNotification(status);
        } else if (status === 500) {
          openNotification(status);
        }
        console.log(status);
      });
  };
  // logout whenever user returns to login page
  useEffect(() => {
    Check.logout(() => sessionStorage.setItem("logged", false));
  }, []);

  let xlGrid = isMobile ? "col-xl-6" : "col-xl-4";
  return (
    <FormCon>
      <MyRow className="row justify-content-md-center">
        <div className={`col-xs-4 col-md-8 col-lg-6 ${xlGrid} offset-xs-2`}>
          <MyForm>
            <TitleCon>
              <Title>Tizimga kirish</Title>
            </TitleCon>
            <Form name="basic" initialValues={{ remember: false }} onFinish={handleSubmit}>
              <Form.Item name="username" rules={[{ required: true, message: "Please input your username!" }]}>
                <MyInput autoFocus autoComplete={null} prefix={<UserOutlined />} />
              </Form.Item>
              <Form.Item name="password" rules={[{ required: true, message: "Please input your password!" }]}>
                <MyInputPassword prefix={<KeyOutlined />} />
              </Form.Item>

              <Form.Item>
                <SubmitBtn icon={<LoginOutlined />} type="primary" htmlType="submit">
                  Kirish
                </SubmitBtn>
              </Form.Item>
            </Form>
          </MyForm>
        </div>
      </MyRow>
    </FormCon>
  );
};
