// react
import React, { useState, useEffect } from "react";
// third party
import { Layout } from "antd";
import jwt from "jsonwebtoken";

// custom
import { SideBar } from "../sidebar";
import { Navbar } from "../header";
import { IContent } from "../content";
import MobileSideNav from "../sidebar/moleculas/MobileSideNav";

export const MyContent = (props) => {
  const { history } = props;

  const [user, setUser] = useState({ map: false, dm: false, df: false, hisotry: false });
  const [collapsed, setCollapsed] = useState(false);
  const [path, setPath] = useState(window.location.pathname);

  const toggle = () => {
    setCollapsed(!collapsed);
  };
  // prevent back to login page when refresh page
  useEffect(() => {
    sessionStorage.setItem("logged", true);
    let token = localStorage.getItem("token");
    if (token) {
      let decoded = jwt.decode(token);
      setUser(decoded);
    }
  }, []);

  const onMenuClick = (path) => {
    setPath(path);
    props.history.push(path);
  };

  return (
    <Layout style={{ height: "100%" }}>
      <SideBar
        collapsed={collapsed}
        onMenuClick={onMenuClick}
        currentPathname={props.location.pathname}
        user={user}
      />
      <MobileSideNav
        collapsed={collapsed}
        onMenuClick={onMenuClick}
        currentPathname={props.location.pathname}
        toggle={toggle}
        user={user}
      />
      <Layout className="site-layout">
        <Navbar history={history} collapsed={collapsed} toggle={toggle} />
        <IContent match={props.match} path={path} user={user} />
      </Layout>
    </Layout>
  );
};
