import React from "react";
import { Route, Redirect } from "react-router-dom";
import Check from "../func/check";

export const PrivateRoute = ({ component: Component, ...args }) => {
  return (
    <Route
      {...args}
      render={(props) => {
        if (Check.isChecked()) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/" />;
        }
      }}
    ></Route>
  );
};
