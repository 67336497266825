import { Layout, Input, Button } from "antd";
import styled from "styled-components";

export const FormCon = styled(Layout.Content)`
  @media (max-height: 850px) {
    margin-top: 100px;
  }
  @media (max-height: 750px) {
    margin-top: 45px;
  }
  @media (max-height: 650px) {
    margin-top: 5px;
  }
  @media (max-height: 600px) {
    margin-top: 0px;
  }
  margin-top: 170px;
`;

export const MyForm = styled.div`
  padding: 30px;
  border: 1px solid lightblue;
  border-radius: 5px;

  backdrop-filter: blur(5px);
  /* background: #6ce0131f; */
  box-shadow: 0px 0px 7px 7px #4ac0e661;
`;

export const MyInput = styled(Input)`
  line-height: 30px !important;
`;

export const MyInputPassword = styled(Input.Password)`
  line-height: 30px !important;
`;

export const SubmitBtn = styled(Button)`
  height: 40px;
  background: #09be1e;
  border-color: gold;
  margin-top: 40px;
  font-size: 16px;
  width: 100%;
  outline: none;
`;

export const MyRow = styled.div`
  margin-left: 0px;
  margin-right: 0px;
`;
