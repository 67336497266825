// react
import React from "react";
import ReactDOM from "react-dom";
// third party
import { BrowserRouter as Router, Route, Switch, HashRouter } from "react-router-dom";
import "antd/dist/antd.css";
import "./assets/css/bootstrap.css";
// custom
import Login from "./components/routes/login/page";
import { MyContent } from "./components/layout";
import { PrivateRoute } from "./components/routes/login/moleculas";
import "./assets/css/custom.css";

import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <HashRouter>
    {/* <Router basename="/gis"> */}
    <Switch>
      <PrivateRoute path="/content" component={MyContent} />
      <Route path="/" component={Login} />
    </Switch>
    {/* </Router> */}
  </HashRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
