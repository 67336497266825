import React from "react";
import { LoginMainCon, LayoutIt } from "../atoms";
import { Navbar, FormIt } from "../moleculas";

const Login = ({ history }) => {
  return (
    <>
      <LoginMainCon> </LoginMainCon>
      <LayoutIt>
        <Navbar />
        <FormIt history={history} />
      </LayoutIt>
    </>
  );
};

export default Login;
